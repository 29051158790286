import { createApp } from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import VueBarcode from "@chenfengyuan/vue-barcode";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import "primevue/resources/themes/aura-light-green/theme.css";

const app = createApp(App);

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '90fce5b7-5142-4e82-ad44-2ae39d35a81c',
    clientToken: 'pub796a029b87519107781f441e148d1468',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'jd-web',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

app.use(PrimeVue);
app.use(router);
app.use(store);
app.component(VueBarcode.name, VueBarcode);
app.component("Dialog", Dialog);

app.mount("#app");
